<template>
  <div class="user-profile">

    <HeaderParallax image="/app_images/information.webp" :options="{opacity: '90', crop: 'crop', x: 0, y: 0, gravity: 'north_east', background: 'black' }"/>

    <v-container>

      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="5" md="4" lg="3" ref="animate">
              <Spacer height="12px"/>

              <transition name="slide-fade">
                <v-card v-if="entries.showUser">

                  <v-card-title class="justify-center bright darken-1">

                    <v-menu>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon class="card-title-menu-btn" color="secondary lighten-4"><v-icon>mdi-dots-vertical</v-icon></v-btn>
                      </template>

                      <v-list>
                        <v-list-item v-for="(action, i) in actions" :key="i" @click="action.action">
                          <v-list-item-title>{{ action.text }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>

                    <div class="avatar">
                      <v-avatar
                        size="110"
                        color="primary">

                        <div v-if="userClone.profile_image">
                          <cld-image :publicId="userClone.profile_image" :alt="fullName">
                            <cld-transformation height="125" crop="fit"/>
                          </cld-image>
                        </div>

                        <div v-else>
                          <v-icon size="90" color="bright darken-3">mdi-account</v-icon>
                        </div>
                      </v-avatar>

                      <span class="avatar-controls">
                        <v-btn color="secondary lighten-4" v-if="userClone.profile_image" fab x-small class="upload-btn" @click="clearImage">
                          <v-icon size="42" color="bright darken-1">mdi-delete-circle-outline</v-icon>
                        </v-btn>

                        <v-btn v-else icon class="upload-btn" @click="uploadImages('images', { multiple: false, croppingAspectRatio: 1.0 })">
                          <v-icon size="30" color="secondary lighten-4">mdi-cloud-upload</v-icon>
                        </v-btn>
                      </span>
                    </div>
                  </v-card-title>

                  <v-card-text>
                    <v-form name="profile" @submit.prevent="saveProfile" ref="profile">
                      <spacer height="25px"/>

                      <v-text-field
                        disabled
                        color="primary darken-1"
                        v-model="userClone.email"
                        name="email"
                        label="דוא״ל"/>

                      <v-text-field
                        color="primary darken-1"
                        v-model="userClone.first_name"
                        :rules="[rules.required]"
                        name="first_name"
                        label="שם פרטי"/>

                      <v-text-field
                        color="primary darken-1"
                        v-model="userClone.last_name"
                        :rules="[rules.required]"
                        name="last_name"
                        label="שם משפחה"/>

                      <v-text-field
                        color="primary darken-1"
                        v-model="phone"
                        name="phone"
                        :rules="[rules.number]"
                        :label="$t('user.phone')"/>

                      <v-text-field
                        color="primary darken-1"
                        v-model="mobile"
                        :rules="[rules.number]"
                        name="mobile"
                        :label="$t('user.mobile')"/>

                      <!-- <v-text-field
                        v-for="(address, i) in userClone.addresses"
                        :key="i"
                        color="primary darken-1"
                        v-model="userClone.addresses"
                        name="address"
                        label="address"/> -->

                      <v-btn type="submit" color="primary">{{$t('common.save')}}</v-btn>
                    </v-form>
                  </v-card-text>
                </v-card>
              </transition>

              <spacer height="35px"/>

              <transition name="slide-fade">
                <v-card v-if="entries.showPassword">
                  <v-card-title class="justify-center secondary lighten-5 primary--text" v-text="'change pass'"/>
                  <v-divider/>

                  <v-card-text>
                    <v-form name="change_password" @submit.prevent="changePassword" ref="changePassword">
                      <v-text-field
                        color="primary darken-1"
                        v-model="oldPassword"
                        name="oldPassword"
                        type="password"
                        :rules="[rules.required]"
                        label="סיסמה נוכחית"/>

                      <v-text-field
                        color="primary darken-1"
                        v-model="newPassword"
                        name="newPassword"
                        type="password"
                        :rules="[rules.verify, rules.required]"
                        label="סיסמה חדשה"/>

                      <v-text-field
                        color="primary darken-1"
                        v-model="verifyPassword"
                        name="verifyPassword"
                        type="password"
                        :rules="[rules.verify, rules.required]"
                        label="סיסמה חדשה שנית"/>

                      <v-btn type="submit" color="primary" :disabled="passwordDisabled">{{$t('common.update')}}</v-btn>
                    </v-form>
                  </v-card-text>
                </v-card>
              </transition>
            </v-col>

            <v-col cols=12 sm=7 md=8 lg=9>
              <transition-group name="slide-fade-reverse" tag="div" class="row">
                <v-col cols=12 md="6" v-for="e in estates" :key="e.id" class="estate">
                  <v-menu>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon class="card-title-menu-btn" color="secondary lighten-4"><v-icon>mdi-dots-vertical</v-icon></v-btn>
                    </template>

                    <v-list>
                      <v-list-item v-for="(action, i) in estateActions" :key="i" @click="action.action(e)" v-show="action.show(e)">
                        <v-list-item-title>{{ action.text }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <EstateBox :estate="e"/>
                </v-col>
              </transition-group>

              <v-row v-intersect.quiet="getUserEstates">
                <v-col>
                  <v-flex class="text-center padding-tb-20">
                    <v-btn  v-if="!endOfEstatesList"
                            :loading="loading"
                            :block="$vuetify.breakpoint.xsOnly"
                            class="padding-sides-50"
                            color="secondary"
                            @click="getUserEstates">
                      {{ $t("common.load_more") }}
                    </v-btn>
                  </v-flex>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-snackbar v-model="snackbar"
                  :color="snackbarColor">
        <vue-markdown :key="snackbarMessage">{{snackbarMessage}}</vue-markdown>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import UserService from '@/app/modules/user/user-service'
import EstatesService from '@/app/modules/estates/estates-service'
import rules from '@/common/mixins/validations-rules-mixin.js'
import cloudinaryWidget from '@/common/mixins/cloudinary-widget-mixin.js'
import EstateBox from '@/app/modules/estates/components/estate-box'
import HeaderParallax from '@/app/common/components/header-parallax'

export default {

  name: 'UserProfile',

  components: {
    EstateBox,
    HeaderParallax
  },

  data () {
    return {
      page: 1,
      loading: false,
      snackbar: false,
      endOfEstatesList: false,
      snackbarMessage: '',
      snackbarColor: '',
      passwordDisabled: false,
      userClone: {},
      entries: {
        showUser: false,
        showPassword: false
      },
      oldPassword: '',
      newPassword: '',
      verifyPassword: '',
      actions: [
        {
          text: this.$t('common.logout'),
          action: this.logout
        },
        {
          text: this.$t('user.contact'),
          action: this.contactSupport
        },
        {
          text: this.$t('user.delete_account'),
          action: this.deleteAccount
        }
      ],
      estateActions: [
        {
          text: this.$t('estates.actions.edit'),
          action: this.openEstate,
          show: () => true
        },
        {
          text: this.$t('estates.actions.hide'),
          action: (e) => this.changeStatus(e, 'hidden'),
          show: (e) => e.status === 'active'
        },
        {
          text: this.$t('estates.actions.activate'),
          action: (e) => this.changeStatus(e, 'active'),
          show: (e) => e.status === 'hidden' && e.status !== 'pending'
        },
        {
          text: this.$t('estates.actions.delete'),
          action: (e) => this.changeStatus(e, 'deleted'),
          show: () => true
        },
        {
          text: this.$t('estates.actions.mark_as_sold'),
          action: (e) => this.changeStatus(e, 'sold'),
          show: () => true
        }
      ],
      rules: {
        verify: val => {
          return this.newPassword === val || 'unmatched passwords'
        }
      },
      estates: []
    }
  },

  mixins: [rules, cloudinaryWidget],

  computed: {
    ...mapGetters('user', ['user']),

    fullName () {
      return `${this.user.first_name} ${this.user.last_name}`
    },

    phone: {
      set (val) {
        const phone = this.userClone.phones.find((p) => p.name === 'phone')
        if (phone) {
          phone.number = val
        } else {
          this.userClone.phones.push({
            name: 'phone',
            number: val
          })
        }
      },
      get () {
        const obj = this.userClone.phones.find((p) => p.name === 'phone')
        if (obj && obj.number) {
          return obj.number
        }
        return ''
      }
    },

    mobile: {
      set (val) {
        const mobile = this.userClone.phones.find((p) => p.name === 'mobile')
        if (mobile) {
          mobile.number = val
        } else {
          this.userClone.phones.push({
            name: 'mobile',
            number: val
          })
        }
      },
      get () {
        const obj = this.userClone.phones.find((p) => p.name === 'mobile')
        if (obj && obj.number) {
          return obj.number
        }
        return ''
      }
    }
  },

  methods: {
    ...mapActions('user', ['saveUser', 'logout', 'deleteUser']),

    openEstate (e) {
      const route = this.$router.resolve({ name: 'edit_estate', params: { slug: e.slug } })
      window.open(route.href)
    },

    async changeStatus (estate, status) {
      estate.status = status
      try {
        await EstatesService.saveEstate(estate)
        this.showSnackbar('success')
      } catch (e) {
        console.error(e)
        this.showSnackbar('error')
      }
    },

    showSnackbar (type) {
      this.snackbar = false
      this.snackbarMessage = this.$t(`common.${type}_message`)
      this.snackbarColor = type
      this.snackbar = true
    },

    clearImage () {
      this.userClone.profile_image = ''
      this.saveProfile()
    },

    saveUplodes (img) {
      if (!img[0].uploadInfo) return
      this.userClone.profile_image = img[0].uploadInfo.public_id
      this.saveProfile()
    },

    async changePassword () {
      this.passwordDisabled = true
      // console.log('changePassword')
      if (this.$refs.changePassword.validate()) {
        try {
          await UserService.changePassword(this.user.id, this.oldPassword, this.newPassword)
          this.showSnackbar('success')
          this.$refs.changePassword.reset()
        } catch (e) {
          console.error(e)
          this.showSnackbar('error')
        }
      }
      this.passwordDisabled = false
    },

    async saveProfile () {
      try {
        await this.saveUser(this.userClone)
        this.userClone = this.user
        this.showSnackbar('success')
      } catch (e) {
        this.showSnackbar('error')
        console.error(e)
      }
    },

    contactSupport () {
      this.$router.push({ name: 'contactUs' })
    },

    deleteAccount () {
      try {
        this.deleteUser()
      } catch (e) {
        console.error(e)
      }
    },

    componentsTiming () {
      Object.keys(this.entries).forEach((e, i) => {
        setTimeout(() => {
          this.entries[e] = true
        }, 300 * (i + 1))
      })
    },

    async getUserEstates () {
      this.loading = true
      const estates = await EstatesService.getUserEstates(this.page)
      if (!estates.length) {
        this.endOfEstatesList = true
      }
      this.estates = this.estates.concat(estates)
      this.loading = false
      this.page++
    }
  },

  created () {
    this.folder = `users${process.env.NODE_ENV === 'development' ? '_dev' : ''}/${this.user.id.replace('::', '')}`
    this.componentsTiming()
    Object.assign(this.userClone, this.user)
    this.getUserEstates()
  }
}
</script>

<style lang="scss">

  .user-profile {

    .container {
      margin-top: -75px;
    }

    .v-card__text {
      padding-top: 0;
    }

    .avatar {
      position: relative;

      .avatar-controls {
        position: absolute;
        top: 0;
        right: 1px;
      }
    }

    .estate {
      position: relative;

      .v-btn {
        position: absolute;
        top: 15px;
        left: 15px;
        right: auto;
        z-index: 1;
      }
    }
  }
</style>
